<template>
    <svg width="299" height="211" viewBox="0 0 299 211" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.3" filter="url(#filter0_iiii_755_5907)">
<path d="M56.1124 183.12H79.4004C95.1444 183.12 103.016 175.029 103.016 158.848V52.2479C103.016 36.0666 95.1444 27.9759 79.4004 27.9759H56.1124C40.3684 27.9759 32.4964 36.0666 32.4964 52.2479V158.848C32.4964 175.029 40.3684 183.12 56.1124 183.12ZM83.9924 211H51.1924C35.0111 211 22.4377 206.517 13.4724 197.552C4.50708 188.587 0.0244141 176.123 0.0244141 160.16V50.9359C0.0244141 34.7546 4.50708 22.2906 13.4724 13.5439C22.4377 4.57861 35.0111 0.0959473 51.1924 0.0959473H83.9924C99.9551 0.0959473 112.419 4.57861 121.384 13.5439C130.35 22.2906 134.832 34.7546 134.832 50.9359V160.16C134.832 176.123 130.35 188.587 121.384 197.552C112.419 206.517 99.9551 211 83.9924 211Z" fill="#010013"/>
<path d="M171.345 205.424V175.248C171.345 161.691 177.14 150.32 188.729 141.136L250.721 90.6239C261.217 82.3146 266.465 72.6933 266.465 61.7599V50.9359C266.465 35.1919 258.265 27.3199 241.865 27.3199H225.793C217.484 27.3199 211.471 29.1786 207.753 32.8959C204.036 36.6133 202.177 42.6266 202.177 50.9359V63.0719C202.177 67.0079 200.319 68.9759 196.601 68.9759H175.609C171.892 68.9759 170.033 67.0079 170.033 63.0719V50.2799C170.033 34.3173 174.516 21.9626 183.481 13.2159C192.447 4.46928 205.02 0.0959473 221.201 0.0959473H246.785C262.748 0.0959473 275.212 4.46928 284.177 13.2159C293.143 21.9626 297.625 34.3173 297.625 50.2799V63.7279C297.625 80.7839 290.956 94.7786 277.617 105.712L214.641 157.208C209.612 161.363 207.097 166.283 207.097 171.968C207.097 177.765 211.797 182.464 217.593 182.464H292.377C296.313 182.464 298.281 184.323 298.281 188.04V205.424C298.281 209.141 296.313 211 292.377 211H177.249C173.313 211 171.345 209.141 171.345 205.424Z" fill="#010013"/>
</g>
<defs>
<filter id="filter0_iiii_755_5907" x="-11.1756" y="-8.81834" width="309.457" height="228.504" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="11.6571"/>
<feGaussianBlur stdDeviation="4.34286"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.494118 0 0 0 0 0.00784314 0 0 0 0 0.960784 0 0 0 1 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_755_5907"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1.14286"/>
<feGaussianBlur stdDeviation="2.85714"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.976471 0 0 0 0 0.976471 0 0 0 0.88 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_755_5907" result="effect2_innerShadow_755_5907"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1.14286"/>
<feGaussianBlur stdDeviation="0.457143"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_755_5907" result="effect3_innerShadow_755_5907"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-11.2" dy="-8.91429"/>
<feGaussianBlur stdDeviation="11.6571"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.752941 0 0 0 0 0.0156863 0 0 0 0 0.996078 0 0 0 0.6 0"/>
<feBlend mode="normal" in2="effect3_innerShadow_755_5907" result="effect4_innerShadow_755_5907"/>
</filter>
</defs>
</svg>

</template>